<template>
  <div id="app">
    <header class="navbar cto-header navbar-expand-md navbar-light">
      <div class="container">
        <a class="navbar-brand logo" href="#">Efficient Leaders</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="ml-auto navbar-nav">
            <li class="nav-item active">
              <a class="nav-link" href="#">About</a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" href="#">Initiatives</a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" href="#">Benefits</a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" href="#">Pricing</a>
            </li>
          </ul>
          <a class="btn btn-small btn-primary ml-2" href="https://app.efficientleaders.com">Login</a>
        </div>
      </div>
    </header>

    <section class="hero-section">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="hero-content">
              <h1>A global network of senior tech leaders who help each other succeed.</h1>
              <p class="mb-4 mb-md-5">Be part of a trusted network of senior tech leaders from around the world. Get discounts and upgrades on software, hardware, travel, and other business related items</p>
              <a class="btn btn-primary" href="https://csuitecircle.typeform.com/to/nGtyVynE">Request an Invite</a>
            </div>
          </div>
          <div class="col-md-6">
            <div class="hero-image">
              <img src="@/assets/images/hero-image.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="key-metrics">
      <div class="container">
        <div class="metrics-inner">
          <div class="stats">
            <h4>5,328</h4>
            <h5>Leaders</h5>
          </div>
          <div class="stats">
            <h4>41</h4>
            <h5>Countries</h5>
          </div>
          <div class="stats">
            <h4>812</h4>
            <h5>Cities</h5>
          </div>
        </div>
      </div>
    </section>


    <section class="members-from">
      <div class="container">
        <h6 class="subtitle">INCLUDING SENIOR TECH LEADERS FROM</h6>
        <div class="row justify-content-center align-items-center">
          <div class="col-6 mt-3 mt-md-0 col-md-2">
            <div class="image">
              <img src="@/assets/images/members/netflix.png">
            </div>
          </div>
          <div class="col-6 mt-3 mt-md-0 col-md-2">
            <div class="image">
              <img src="@/assets/images/members/facebook.png">
            </div>
          </div>
          <div class="col-6 mt-3 mt-md-0 col-md-2">
            <div class="image">
              <img src="@/assets/images/members/apple.png">
            </div>
          </div>
          <div class="col-6 mt-3 mt-md-0 col-md-2">
            <div class="image">
              <img src="@/assets/images/members/amazon.png">
            </div>
          </div>
          <div class="col-6 mt-3 mt-md-0 col-md-2">
            <div class="image">
              <img src="@/assets/images/members/spotify.png">
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="members-around">
      <div class="container">
        <h2>Connect with <span class="txt-primary">senior tech leaders</span> around the world</h2>
        <div class="map-inner">
          <img src="@/assets/images/map.svg" alt="" />
        </div>
        <a class="btn btn-primary" href="https://csuitecircle.typeform.com/to/nGtyVynE">Request an Invite</a>
      </div>
    </section>


    <section class="thoughts">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-5">
            <div class="thoughts-img"><img src="@/assets/images/thoughts.png" alt="" /></div>
          </div>
          <div class="col-md-6 col-lg-7">
            <div class="thought-inner">
              <span><img src="@/assets/images/quote.svg" alt=""></span>
              <div class="quote-text">Leading successful tech teams is hard. We started Efficient Leaders because we wanted to connect with and learn best practice from our peers. We couldn’t find an active network of senior tech leaders, so we started our own.</div>
              <div class="author">Janakan Arulkumarsan</div>
              <div class="designation">Co-Founder, Efficient Leaders</div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="initiatives">
      <div class="container">
        <h2>Initiatives</h2>
        <div class="initiatives-inner">
          <div class="row">
            <div class="col-md-6 col-lg-5">
              <div class="column-img">
                <img class="particle1" src="@/assets/images/particle1.png">
                <img class="main-img" src="@/assets/images/initiative1.png" alt="" />
              </div>
            </div>
            <div class="col-md-6 col-lg-5">
              <div class="initiatives-content">
                <h3>Peer Groups</h3>
                <p>Accelerate your leadership by joining a small, trusted team of seasoned senior tech leaders and technology leaders, meeting monthly for a guided discussion facilitated by an experienced coach. Overcome challenges together, learn from your peers, and grow in strategy, planning and other leadership skills.</p>
                <a class="btn btn-default" href="https://csuitecircle.typeform.com/to/nGtyVynE">Learn More</a>
              </div>
            </div>
            <div class="col-lg-2"></div>
          </div>

          <div class="row row-reverse">
            <div class="col-md-6 col-lg-5">
              <div class="column-img">
                <img class="particle2" src="@/assets/images/particle2.png">
                <img class="main-img" src="@/assets/images/initiative2.png" alt="" />
              </div>
            </div>
            <div class="col-md-6 col-lg-5">
              <div class="initiatives-content">
                <h3>Cloud Pool</h3>
                <p>As a community, we collectively spend millions of dollars on cloud services each month. By combining our spend, we’re able to negotiate superior pricing from major cloud providers, allowing our members to save up to 70% on their annual cloud bills. Our in-house Cloud Optimization Advisors are also available to help members optimize and secure their cloud deployments.</p>
                <a class="btn btn-default" href="https://csuitecircle.typeform.com/to/nGtyVynE">Learn More</a>
              </div>
            </div>
            <div class="col-lg-2"></div>
          </div>

          <div class="row">
            <div class="col-md-6 col-lg-5">
              <div class="column-img">
                <img class="particle3" src="@/assets/images/particle3.png">
                <img class="main-img" src="@/assets/images/initiative3.png" alt="" />
              </div>
            </div>
            <div class="col-md-6 col-lg-5">
              <div class="initiatives-content">
                <h3>Talent Pool</h3>
                <p>Finding 10x talent is hard. Our community Talent Pool consists of hundreds of carefully vetted professionals - including senior tech leaders-between-jobs, data scientists, cloud architects, engineers, designers and more – ready to consult or join your team with as little as 3 days’ notice. Scaling your team is as easy as scaling your servers.</p>
                <a class="btn btn-default" href="https://csuitecircle.typeform.com/to/nGtyVynE">Learn More</a>
              </div>
            </div>
            <div class="col-lg-2"></div>
          </div>

        </div>
      </div>
    </section>

    <section class="benefits">
      <div class="container">
        <h2 class="text-center">Other Benefits</h2>
        <p class="subtitle">We’re building a coaching and mentoring movement to help technology leaders and their teams grow, build sustainable working practices, build confidence and become more resilient to change.</p>
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="benefit-card">
              <div class="card-image"><img src="@/assets/images/member-discounts.svg" alt=""></div>
              <h4>Member Discounts</h4>
              <p>Our member-only discounts help you save on everything from laptops to cloud services.</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="benefit-card">
              <div class="card-image"><img src="@/assets/images/peer-mentoring.svg" alt=""></div>
              <h4>Peer Mentoring</h4>
              <p>Our Peer Mentoring program lets you mentor – and be mentored – by other senior tech leaders in our community.</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="benefit-card">
              <div class="card-image"><img src="@/assets/images/thought-leadership.svg" alt=""></div>
              <h4>Thought Leadership</h4>
              <p>Our member-only events, interviews, and discussion groups help you discover emerging trends and implement best practice.</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="benefit-card">
              <div class="card-image"><img src="@/assets/images/professional-awards.svg" alt=""></div>
              <h4>Professional Awards</h4>
              <p>Nominate senior tech leaders and senior tech leaders for one of our prestigious Efficient Leaders Awards or find yourself on one of our peer-nominated Top CTO lists.</p>
            </div>
          </div>
        </div>
      </div>
    </section>



    <section class="pricing">
      <div class="container">
        <h2 class="text-center">Pricing</h2>
        <p class="subtitle">A pricing to connect with other and senior technology leaders in your local area and across other cities around the world.</p>
        <div class="pricing-box">
          <h4>Free, by invitation only</h4>
          <p>If you think you’re a good fit, request an invite</p>
          <a class="btn btn-primary" href="https://csuitecircle.typeform.com/to/nGtyVynE">Request an Invite</a>
        </div>
      </div>
      <div class="pricing-background">
        <img class="pricing-left" src="@/assets/images/pricing-left.svg" alt="">
        <img class="pricing-right" src="@/assets/images/pricing-right.svg" alt="">
      </div>
    </section>

    <section class="invite">
      <div class="container">
        <h4>Efficient Leaders is intended for <strong>senior technology leaders</strong> from companies with <strong>at least US$1 million</strong> in annual revenue.</h4>
        <p>Membership is free, by invitation only.<br/>If you think you’re a good fit, request an invite.</p>
        <a class="btn btn-primary" href="https://csuitecircle.typeform.com/to/nGtyVynE">Request an Invite</a>
      </div>
    </section>

    <footer class="footer">
      <div class="container">
        <div class="footer-inner">
          <div class="footer-left">
            <div class="footer-logo">Efficient Leaders</div>
            <div class="footer-menu">
              <ul>
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">Terms &amp; Conditions</a></li>
              </ul>
            </div>
            <p class="copyright">© 2021 C-Suite Group. All Rights Reserved.</p>
          </div>
          <div class="footer-right">
            <div class="address">
              <img src="@/assets/images/cs-icons-marker.svg" />
              <span>36 Sam Leong Road,<br />Singapore 357741</span>
            </div>
            <ul class="social">
              <li><a href="#"><img src="@/assets/images/cs-icons-facebook-filled.svg" alt="" /></a></li>
              <li><a href="#"><img src="@/assets/images/cs-icons-linkedin-filled.svg" alt="" /></a></li>
              <li><a href="#"><img src="@/assets/images/cs-icons-twitter-filled.svg" alt="" /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
.navbar-brand
{
  font-size: 30px;
}
.footer-logo
{
  color: white;
  font-size: 30px;
}
</style>
